import "./HomePage.css";
import React, { useState, useEffect } from "react";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import GeneralSection from "./Sections/General.Section";
import DepenseSection from "./Sections/Depense.Section";
import RecetteSection from "./Sections/Recette.Section";
import MenuHamburger from "../components/MenuHamburger";
import PopUpComponent from "../components/PopUpComponent";
import PostForm from "../data/FORM/PostForm";
import DeleteFormPartByID from "../data/GET/DELETE/DeleteFormPartByID";

function Home(props) {
  const [isPopUpVisible, setPopupVisible] = useState(false);
  const [buttonClass, setButtonClass] = useState("formButton");
  //Default value of the form
  let SetDefaultValues;
  if (props?.location?.state?.Fiche) {
    SetDefaultValues = props.location.state.Fiche;
  }
  if (!SetDefaultValues) {
    SetDefaultValues = {};
  }
  //Form
  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors, isDirty, touchedFields },
    trigger,
    getValues,
    setFocus,
  } = useForm({ defaultValues: SetDefaultValues });

  // FORMULAIRE TERME DE PAIEMENT
  const {
    fields: termFields,
    append: termAppend,
    remove: termRemove,
  } = useFieldArray({
    name: "term",
    control,
  });
  useEffect(() => {
    if (termFields.length === 0) {
      addNewLineTermeDePaiement(false);
    }
  }, [setFocus]);
  //FORMULAIRE RECETTE

  const {
    fields: receiptFields,
    append: receiptAppend,
    remove: receiptRemove,
  } = useFieldArray({
    name: "receipt",
    control,
  });
  useEffect(() => {
    if (receiptFields.length === 0) {
      addNewLineRecette(false);
    }
  }, [receiptFields.length]);

  //Ajout de ligne
  function addNewLineRecette(focus = true, termeIndex) {
    receiptAppend(
      {
        collectionDate: "",
        comment: "",
        customerName: "",
        forecastHT: "",
        forecastTTC: "",
        invoiceDate: "",
        productCode: watch("productCoding"),
        serviceType: "",
        supportYear: "",
      },
      { shouldFocus: true }
    );
  }
  function addNewLineTermeDePaiement(focus = true) {
    termAppend(
      { paymentName: "", paymentDate: "", amount: "", rate: "" },
      { shouldFocus: focus }
    );
    if (termFields.length >= receiptFields.length) {
      receiptAppend(
        {
          collectionDate: "",
          comment: "",
          customerName: "",
          forecastHT: "",
          forecastTTC: "",
          invoiceDate: "",
          productCode: "",
          serviceType: "",
          supportYear: "",
        },
        { shouldFocus: focus }
      );
    }
  }
  //

  //Gestion de la suppression de ligne : Terme de Paiement - Recette
  function deleteTermLine(index) {
    let termId = getValues("term[" + index + "].id");
    let Associatereceipt = getValues("receipt[" + index + "]");
    if (termId) {
      DeleteFormPartByID("term", termId).then((response) => {
        if (response === 204) {
          console.log("Deleted correctly");
        } else {
          console.log(
            "NOT DELETED: The ressource could not be deleted on the server, request status : ",
            response
          );
        }
      });
    }
    if (Associatereceipt?.id) {
      DeleteFormPartByID("receipt", Associatereceipt?.id).then((response) => {
        if (response === 204) {
          console.log("Deleted correctly");
        } else {
          console.log(
            "NOT DELETED: The ressource could not be deleted on the server, request status : ",
            response
          );
        }
      });
    }
    if (Associatereceipt) {
      receiptRemove(index);
    }
    termRemove(index);
  }
  function deleteReceiptLine(index) {
    let Associateterm = getValues("term[" + index + "]");
    let receiptId = getValues("receipt[" + index + "].id");
    if (receiptId) {
      DeleteFormPartByID("receipt", receiptId).then((response) => {
        if (response === 204) {
          console.log("Deleted correctly");
        } else {
          console.log(
            "NOT DELETED: The ressource could not be deleted on the server, request status : ",
            response
          );
        }
      });
    }
    if (Associateterm) {
      if (Associateterm?.id) {
        DeleteFormPartByID("term", Associateterm?.id).then((response) => {
          if (response === 204) {
            console.log("Deleted correctly");
          } else {
            console.log(
              "NOT DELETED: The ressource could not be deleted on the server, request status : ",
              response
            );
          }
        });
      }
      termRemove(index);
    }
    receiptRemove(index);
  }
  function CheckIfLoadedByForm(value) {
    if (SetDefaultValues[value]) {
      return true;
    }
    return false;
  }

  //Date en haut de la fiche, Soit on en crée une pour le visuel soit on récupère celle du serveur
  //La Date ne sera pas envoyé -> Géré par le serveur
  var lastEditDate = undefined;
  var creationDate = new Date();
  if (SetDefaultValues?.creationDate) {
    creationDate = new Date(SetDefaultValues?.creationDate);
    if (SetDefaultValues?.lastEditDate) {
      lastEditDate = new Date(SetDefaultValues?.lastEditDate);
      lastEditDate =
        String(lastEditDate.getDate()).padStart(2, "0") +
        "/" +
        String(lastEditDate.getMonth() + 1).padStart(2, "0") +
        "/" +
        lastEditDate.getFullYear();
    }
  }
  creationDate =
    String(creationDate.getDate()).padStart(2, "0") +
    "/" +
    String(creationDate.getMonth() + 1).padStart(2, "0") +
    "/" +
    creationDate.getFullYear();
  // Submit function
  const [errorOnSubmit, seterrorOnSubmit] = useState("");

  function onSubmit(values) {
    setButtonClass("formButton disabledformButton");
    //setPopupVisible(true);

    PostForm(values)
      .then((PostedCard) => {
        console.log("CARD POSTED");
        props.history.push("Fiches");
      })
      .catch((e) => {
        console.log("ERROR HANDLING", e);
        setButtonClass("formButton");
        seterrorOnSubmit("Impossible d'envoyer le formulaire.");
      });
  }
  return (
    <>
      {isPopUpVisible ? (
        <PopUpComponent setPopupVisible={setPopupVisible}>
          La suppression d'une ligne est définitive. Êtes-vous sûr de vouloir
          supprimer cette ligne ?
          <div className="ButtonPopUp">
            <div
              className="LigneFicheOpenButton ButtonCancelPopUp"
              onClick={() => setPopupVisible(false)}
            >
              Annuler
            </div>
            <div
              className="LigneFicheOpenButton"
              onClick={() => console.log("HEY")}
            >
              Supprimer
            </div>
          </div>
        </PopUpComponent>
      ) : (
        <></>
      )}
      <div className="HomeContainer">
        <div className="PageContent">
          <div className="PageHeader">
            <h1 className="title">
              {SetDefaultValues?.lastEditDate
                ? "Edition Fiche"
                : "Nouvelle Fiche"}
            </h1>
            <div className="TopRightMenu">
              <div className="DateMaj">Créée le {creationDate}</div>
              <div className="DateMaj">
                {lastEditDate !== undefined
                  ? "Mise à jour le " + lastEditDate
                  : "Aucune date de mise à jour"}
              </div>
              <div className="MenuContainer">
                <MenuHamburger />
              </div>
            </div>
          </div>
          <h2 className="SectionName" id="Général">
            Général
          </h2>
          <div className="SectionContent">
            <FormProvider
              {...{
                handleSubmit,
                register,
                watch,
                setValue,
                formState: { errors, isDirty, touchedFields },
                trigger,
                getValues,
                control,
              }}
            >
              <form className="GeneralForm" onSubmit={handleSubmit(onSubmit)}>
                <GeneralSection
                  CheckIfLoadedByForm={CheckIfLoadedByForm}
                  //Terme de paiement
                  addNewLineTermeDePaiement={addNewLineTermeDePaiement}
                  deleteTermLine={deleteTermLine}
                  termFields={termFields}
                />
                <h2 className="SectionName" id="Recettes">
                  Recettes
                </h2>
                <div className="SectionHomePage">
                  <RecetteSection
                    addNewLineRecette={addNewLineRecette}
                    deleteReceiptLine={deleteReceiptLine}
                    receiptFields={receiptFields}
                    SetDefaultValues={SetDefaultValues}
                  />
                </div>
                <h2 className="SectionName" id="Dépenses">
                  Dépenses
                </h2>
                <DepenseSection />

                <input
                  disabled={buttonClass === "formButton disabledformButton"}
                  className={buttonClass}
                  type="submit"
                  value={
                    SetDefaultValues?.lastEditDate
                      ? "Sauvegarder la fiche"
                      : "Créer la fiche"
                  }
                />
                <p>{errorOnSubmit}</p>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
