import React, { useState } from "react";
import percentsign from "../../icons/percent-sign.png";
import { useFormContext } from "react-hook-form";
import deleteIcon from "../../icons/close.png";
import SimplePopUp from "../../components/SimplePopUp";

import "./TextInput.form.css";
function FormTextInput({
  onSelect,
  pattern,
  percent,
  name,
  required,
  nestedErrorValue,
  onInputChange,
  ...props
}) {
  const [isPopUpVisible, setPopupVisible] = useState(false);

  const {
    register,
    formState: { errors },
  } = useFormContext();

  if (props.EmptySpace) {
    return <div className="InputFormContainer"></div>;
  }
  let defaultClass = "InputForm";
  if (props.longlabel) {
    defaultClass += " LongLabel";
  }

  //Gestion Required :
  let requiredMessage;
  if (required) {
    requiredMessage = "Champ obligatoire";
  } else {
    requiredMessage = false;
  }
  //gestion Field Pattern
  let fieldPattern;
  if (pattern) {
    fieldPattern = pattern;
  }

  const label = required ? props.label ?  props.label + " *" : undefined : props.label


  return (
    <>
      {isPopUpVisible && props.isDeletable ? (
        <SimplePopUp setPopupVisible={setPopupVisible}>
          La suppression d'une ligne est définitive. <br />
          {props?.popUpMessage ? (
            <div>
              {props?.popUpMessage} <br />
            </div>
          ) : (
            <></>
          )}
          <br />
          Êtes-vous sûr de vouloir supprimer cette ligne ?<br />
          <div className="ButtonPopUp">
            <div
              className="LigneFicheOpenButton ButtonCancelPopUp"
              onClick={() => setPopupVisible(false)}
            >
              Annuler
            </div>
            <div
              className="LigneFicheOpenButton"
              onClick={() => {
                props.Deletable();
                setPopupVisible(false);
              }}
            >
              Supprimer
            </div>
          </div>
        </SimplePopUp>
      ) : (
        <></>
      )}
      <div className={"InputFormContainer"}>
        {props?.Deletable && props.isDeletable ? (
          <img
            //onClick={() => props.Deletable()}
            onClick={() => setPopupVisible(true)}
            className="DeleteSign"
            src={deleteIcon}
            alt="Delete Line"
          />
        ) : (
          <></>
        )}
        {percent ? (
          <img src={percentsign} className="PercentSign" alt="v" />
        ) : (
          <></>
        )}
        <LabelInput label={label}/>
        <input
          key={props?.key}
          type="text"
          className={defaultClass}
          id={props.label}
          name={props.label}
          {...register(name, {
            required: requiredMessage,
            pattern: fieldPattern,
          })}
          onSelect={onSelect}
          onBlur={onInputChange}
        />

        <div className="ErrorForm">
          {nestedErrorValue
            ? nestedErrorValue
            : errors[name] && errors[name].message}
        </div>
      </div>
    </>
  );
}

function LabelInput({...props}) {
  if (props.label !== undefined) {
    if (props.label.length)
      return  <label htmlFor={props.label} className={"LabelForm"}>{props.label}</label>
    return <label style={{visibility: "hidden"}}>label</label>
  }
  return <></>
}

export default FormTextInput;
