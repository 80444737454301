import React from "react";
import exit from "../icons/exit.png";
import "./header.css";
import logo from "../image/logo.jpeg";
import auth from "./auth";
import coinsbudget from "../icons/coinsbudget.png";
import home from "../icons/home.png";
import tablette from "../icons/tablette.png";
import user from "../icons/userIcon.svg";
import lock from "../icons/lockIcon.svg"
import {Tooltip} from 'react-tippy';


function Tabs(props) {
  let classNames = "Tab";
  let action = () => props.history.push(props.redirection);
  if (props.disconnect) {
    action = () => {
      auth.logout(() => {
        props.history.push("/login");
      });
    };
  }
  if (props.subTab)
    classNames = "SubTab";

  if (props.user)
    action = () => {}

  if (window.location.pathname === props.redirection) {
    classNames += " active";
  }
  return (
    <button onClick={action} className={classNames}>
      <img className="LogoTab" src={props.icon} alt=""/>
      <div className="Tabname">{props.name}</div>
    </button>
  );
}

function Header(props) {
  return (
    <div className="Header">
      <button
        onClick={() =>
          props.history.push({
            pathname: "/",
            search: "",
            state: {},
          })
        }
        className="LogoButton"
      >
        <img src={logo} className="Logo" alt="logo"/>
      </button>

      <div className="TabsGroup">
        <div className="TabsGroup">
          <Tabs
            history={props.history}
            name="Accueil"
            redirection="/"
            icon={home}
          />
          <Tabs
            history={props.history}
            name="Fiches"
            redirection="/Fiches"
            icon={tablette}
          />
          <Tabs
            history={props.history}
            name="Budgets"
            redirection="/Budgets"
            icon={coinsbudget}
          />
          <Tooltip
            title="Welcome to React"
            position="bottom"
            trigger="click"
            html={(
              <>
                <Tabs
                  history={props.history}
                  name="Changer de mot de passe"
                  redirection="/Password"
                  subTab
                  icon={lock}
                />
                <Tabs
                  history={props.history}
                  disconnect
                  subTab
                  name="Déconnexion"
                  icon={exit}
                />
              </>
            )}
          >
            <Tabs
              history={props.history}
              user
              name="Compte"
              icon={user}
            />
          </Tooltip>
        </div>


      </div>
    </div>
  );
}

export default Header;
