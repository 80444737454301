import apirequest from "../../apiconf";

export default async function DeleteFormPartByID(url, ExpenseID) {
  const response = await apirequest({
    link: url + "/" + ExpenseID,
    method: "DELETE",
  }).then((response) => {
    return response;
  });
  return response.status;
}
