import { saveAs } from "file-saver";
import apirequest from "../apiconf";
export default async function ExportFile({ path, name, ID, ...props }) {
  apirequest({
    download: true,
    link: path + ID,
    method: "GET",
  }).then((res) => {
    if (props?.fileName) {
      saveAs(res, props.fileName);
    } else {
      saveAs(res, "Export " + name + " " + ID);
    }
  });
}
