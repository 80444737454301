import apirequest from "./apiconf";

//Empty Form

export default async function GetAllFiches(props) {
  const response = await apirequest({
    link: "card",
    method: "GET",
    history: props.history,
  });
  return response;
}
