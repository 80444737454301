import React, { useState } from "react";
import "./LoginPage.css";
import logo from "../image/logo.jpeg";
import EnterIcon from "../icons/EnterIcon.svg";
import UserIcon from "../icons/userIcon.svg";
import lockIcon from "../icons/lockIcon.svg";
import auth from "../components/auth";
import { useForm } from "react-hook-form";

function onSubmitForm(formData) {
  return;
}
function Input(props) {
  return (
    <div className="Input">
      <img src={props.icon} className="Icon" alt="" />
      <input
        onChange={(e) => props.onChange(e.target.value)}
        value={props.value}
        className="PlaceHolder"
        placeholder={props.placeholder}
        type={props.type}
        name={props.name}
      />
    </div>
  );
}

function Button(props) {
  const [errorMessage, setErrormessage] = useState(auth.state);
  return (
    <>
      {errorMessage}
      <button
        onClick={() => {
          auth.login({
            loginddata: {
              username: props.valueUserName,
              password: props.valuePassword,
            },
            cb: () => {
              props.history.push("/");
            },
            failure: () => {
              setErrormessage("Informations invalides");
            },
          });
        }}
        className="Button"
        disabled={props.isDiabled}
      >
        <img src={EnterIcon} className="IconQuit" disabled alt="EnterIcon" />
        <div className="Buttontext">{props.children}</div>
      </button>
    </>
  );
}
function Login(props) {
  const { register, handleSubmit } = useForm();

  if (auth.isAuthentificated === true) {
    auth.logout(() => {
      props.history.push("/login");
    });
  }
  const [isButtonDisabled, setisButtonDisabled] = useState(true);
  const [valueUserName, setvalueUserName] = useState("");
  const [valuePassword, setvaluePassword] = useState("");

  function handleUsername(value) {
    setvalueUserName(value);
    setisButtonDisabled(!(value !== "" && valuePassword !== ""));
  }

  function handlePassword(value) {
    setvaluePassword(value);
    setisButtonDisabled(!(value !== "" && valueUserName !== ""));
  }

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <div className="LoginForm">
        <img src={logo} className="Logo" alt="logo" />
        <Input
          {...register("Username", {
            required: true,
          })}
          onChange={handleUsername}
          value={valueUserName}
          icon={UserIcon}
          placeholder="Nom d'utilisateur"
          name="Username"
        />
        <Input
          {...register("password", {
            required: true,
          })}
          onChange={handlePassword}
          value={valuePassword}
          icon={lockIcon}
          placeholder="Mot de passe"
          type="password"
          name="password"
        />
        <Button
          history={props.history}
          valueUserName={valueUserName}
          valuePassword={valuePassword}
          isDiabled={isButtonDisabled}
        >
          Se connecter
        </Button>
      </div>
    </form>
  );
}

export default Login;
