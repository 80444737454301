import GetCard from "./GET/GetCard";
import GetCardDeliverables from "./GET/GetCardDeliverables";
import GetCardTerm from "./GET/GetCardTerm";
import GetCardExpense from "./GET/GetCardExpense";
import GetCardReceipt from "./GET/GetCardReceipt";

async function addContentToCard(card, CardId, request, name) {
  const content = await request(CardId);
  return Object.assign(card, { [name]: content });
}

export default async function GetFullCardByID(CardId) {
  const card = await GetCard(CardId);
  const addedDeliverables = await addContentToCard(
    card,
    CardId,
    GetCardDeliverables,
    "deliverable"
  );
  const addedterm = await addContentToCard(
    addedDeliverables,
    CardId,
    GetCardTerm,
    "term"
  );
  const addedCardExpense = await addContentToCard(
    addedterm,
    CardId,
    GetCardExpense,
    "expense"
  );
  const addedCardReceipt = await addContentToCard(
    addedCardExpense,
    CardId,
    GetCardReceipt,
    "receipt"
  );

  return addedCardReceipt;
}
