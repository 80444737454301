import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
//on utilise ce composant pour recuperer un click à l'exterieur d'un menu (pour pouvoir le fermer)
function useOutsideAlerter(ref, { ActionToDo }) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        ActionToDo();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

function OutsideAlerter(props) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, { ActionToDo: props.ActionToDo });

  return <div ref={wrapperRef}>{props.children}</div>;
}

OutsideAlerter.propTypes = {
  children: PropTypes.element.isRequired,
};

export default OutsideAlerter;
