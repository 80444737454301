import React, { useEffect } from "react";
import CalendarInput from "../../components/form/CalendarInput.form";
import DeleteFormPartByID from "../../data/GET/DELETE/DeleteFormPartByID";

import { useFormContext, useFieldArray } from "react-hook-form";
import FormTextInput from "../../components/form/TextInput.form";

function Deliverable() {
  const {
    fields: deliverableFields,
    append: deliverableAppend,
    remove: deliverableRemove,
  } = useFieldArray({
    name: "deliverable",
  });
  const { getValues } = useFormContext();
  useEffect(() => {
    if (deliverableFields.length === 0) {
      deliverableAppend(
        { name: "", applicationDate: "", comment: "" },
        { shouldFocus: false }
      );
    }
  }, []);

  function deleteDeliverableLine(index) {
    let possibledeliverableID = getValues(`deliverable.${index}.id`);
    if (possibledeliverableID) {
      DeleteFormPartByID("deliverable", possibledeliverableID).then(
        (response) => {
          if (response === 204) {
            console.log("Deleted correctly");
          } else {
            console.log(
              "NOT DELETED: The ressource could not be deleted on the server, request status : ",
              response
            );
          }
        }
      );
    }
    deliverableRemove(index);
  }
  return (
    <>
      <h5>Livrables</h5>
      <div className="TermePaiementContainer">
        {deliverableFields.map((field, index) => (
          <Line
            index={index}
            key={field.id}
            deleteDeliverableLine={deleteDeliverableLine}
          ></Line>
        ))}

        <input
          type="button"
          className="formButton"
          onClick={() =>
            deliverableAppend({ name: "", applicationDate: "", comment: "" })
          }
          value="Ajouter un livrable"
        />
      </div>
    </>
  );
}

function Line({ index, key, deleteDeliverableLine }) {
  const {
    formState: { errors },
  } = useFormContext();
  let label = "Intitulé " + (index + 1);
  return (
    <div className={"FormLine"}>
      <FormTextInput
        isDeletable
        key={key}
        Deletable={() => deleteDeliverableLine(index)}
        name={`deliverable.${index}.name`}
        label={label}
        required
        nestedErrorValue={errors?.deliverable?.[index]?.name?.message}
      />
      <CalendarInput
        key={key}
        required
        name={`deliverable.${index}.applicationDate`}
        label="Date de demande"
        nestedErrorValue={
          errors?.deliverable?.[index]?.applicationDate?.message
        }
      />
      <FormTextInput
        key={key}
        name={`deliverable.${index}.comment`}
        label={"Commentaire"}
      />
    </div>
  );
}

export default Deliverable;
