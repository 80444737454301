import React, {useState} from "react";
import "./Recette.Section.css";
import SelectFromList from "../../components/form/SelectFromList.form";
import CalendarInput from "../../components/form/CalendarInput.form";
import {useFormContext} from "react-hook-form";
import FormTextInput from "../../components/form/TextInput.form";

function RecetteSection({
                            addNewLineRecette,
                            deleteReceiptLine,
                            receiptFields,
                            SetDefaultValues,
                        }) {
    return (
        <div className="SectionTableau">
            <table>
                <thead>
                <tr>
                    <th scope="col">Client</th>
                    <th scope="col">Type de prestation</th>
                    <th scope="col">Codification produit</th>
                    <th scope="col">Prévisionnel HT</th>
                    <th scope="col">Prévisionnel TTC</th>
                    <th scope="col">Date facturation</th>
                    <th scope="col">Date encaissement</th>
                    <th scope="col">Année de prise en charge</th>
                    <th scope="col">Commentaire</th>
                </tr>
                </thead>

                <tbody>
                {receiptFields.map((field, index) => (
                    <TableLine
                        receiptFields={receiptFields}
                        key={field.id}
                        index={index}
                        SetDefaultValues={SetDefaultValues}
                        deleteReceiptLine={deleteReceiptLine}
                    />
                ))}
                </tbody>
            </table>
            <input
                type="button"
                className="formButton"
                onClick={() => addNewLineRecette()}
                value="Ajouter une recette"
            />

            <br/>
            <br/>
        </div>
    );
}

function TableLine({
                       receiptFields,
                       index,
                       SetDefaultValues,
                       deleteReceiptLine,
                   }) {
    const {
        watch,
        getValues,
        setValue,
        formState: {errors},
    } = useFormContext();

    function DoesItExist(value) {
        if (value) {
            return true;
        }
        return false;
    }

    //Remplissage automatique a partir de la section générale
    // On utilise des useState pour voir quelles entrées on déjà été séléctionnées
    const [InputHasBeenSelected, setInputHasbeenSelected] = useState({
        customerName: DoesItExist(SetDefaultValues?.receipt?.[index]?.customerName),
        serviceType: DoesItExist(SetDefaultValues?.receipt?.[index]?.serviceType),
        productCode: DoesItExist(SetDefaultValues?.receipt?.[index]?.productCode),
        forecastHT: DoesItExist(SetDefaultValues?.receipt?.[index]?.forecastHT),
        forecastTTC: DoesItExist(SetDefaultValues?.receipt?.[index]?.forecastTTC),
        supportYear: DoesItExist(SetDefaultValues?.receipt?.[index]?.supportYear),
        invoiceDate: DoesItExist(SetDefaultValues?.receipt?.[index]?.invoiceDate),
    });

    function HandleSelection(field) {
        let SelectedInputs = InputHasBeenSelected;
        SelectedInputs[field] = true;
        setInputHasbeenSelected(SelectedInputs);
    }

    // On défini la valeure des taxes à 20%, modififiable ici :
    let ratetaxes = 0.2;
    // On défini l'année de création de la fiche ici :
    watch("year")
    const YearOfCreation = getValues("year") || new Date().getFullYear();

    //customerName
    if (
        !InputHasBeenSelected.customerName &&
        watch("customer") !== getValues(`receipt.${index}.customerName`)
    ) {
        setValue(`receipt.${index}.customerName`, watch("customer"), {
            shouldValidate: true,
            shouldTouch: false,
        });
    }
    //Année
    if (
        !InputHasBeenSelected.supportYear &&
        getValues(`receipt.${index}.supportYear`) !== YearOfCreation
    ) {
        setValue(`receipt.${index}.supportYear`, YearOfCreation, {
            shouldValidate: true,
            shouldTouch: false,
        });
    }

    // On ne remplis les lignes d'après que pour les lignes correspondant aux Termes de paiements
    if (getValues("term").length - 1 >= index) {
        //Date de Facturation

        if (
            !InputHasBeenSelected.invoiceDate &&
            watch(`term.${index}.paymentDate`) !==
            watch(`receipt.${index}.invoiceDate`)
        ) {
            setValue(
                `receipt.${index}.invoiceDate`,
                getValues(`term.${index}.paymentDate`),
                {
                    shouldValidate: true,
                    shouldTouch: false,
                }
            );
        }
        if (
            !InputHasBeenSelected.serviceType &&
            watch("serviceType") !== watch(`receipt.${index}.serviceType`)
        ) {
            //Type de prestation

            setValue(`receipt.${index}.serviceType`, getValues("serviceType"), {
                shouldValidate: true,
                shouldTouch: false,
            });
        }


        //Remplissage automatique a partir de la section terme de paiement (Ligne par ligne) +HT ou TTC de général

        //Prévisionnel HT

        if (
            watch("HT") === "true" &&
            !InputHasBeenSelected.forecastHT &&
            !InputHasBeenSelected.forecastTTC &&
            watch(`receipt.${index}.forecastHT`) !== watch(`term.${index}.amount`)
        ) {
            setValue(`receipt.${index}.forecastHT`, watch(`term.${index}.amount`), {
                shouldValidate: true,
                shouldTouch: false,
            });
            setValue(
                `receipt.${index}.forecastTTC`,
                watch(`receipt.${index}.forecastHT`) * (1 + ratetaxes),
                {
                    shouldValidate: true,
                    shouldTouch: false,
                }
            );
        }

        //Prévisionnel TTC
        if (
            watch("HT") === "false" &&
            !InputHasBeenSelected.forecastTTC &&
            !InputHasBeenSelected.forecastHT &&
            watch(`receipt.${index}.forecastTTC`) !== watch(`term.${index}.amount`)
        ) {
            setValue(`receipt.${index}.forecastTTC`, watch(`term.${index}.amount`), {
                shouldValidate: true,
                shouldTouch: false,
            });
        }
    }

    //AutoCalculate all stuff
    function onTTCChange(TTCVALUE) {
        setValue(
            `receipt.${index}.forecastHT`,
            (TTCVALUE * (1 / (1 + ratetaxes))).toFixed(2),
            {
                shouldValidate: true,
                shouldTouch: false,
            }
        );
    }

    function onHTChange(HTVALUE) {
        setValue(
            `receipt.${index}.forecastTTC`,
            (HTVALUE * (1 + ratetaxes)).toFixed(2),
            {
                shouldValidate: true,
                shouldTouch: false,
            }
        );
    }

    const dateValues = getValues([`receipt.${index}.invoiceDate`, `receipt.${index}.collectionDate`])
    const newCollectionDate = new Date(dateValues[0]);
    newCollectionDate.setMonth(newCollectionDate.getMonth() + 1);
    let collectionDate = new Date(dateValues[0])
    if (dateValues[1])
        collectionDate = new Date(dateValues[1]);
    if (newCollectionDate.toLocaleDateString() != collectionDate.toLocaleDateString()) {
        setValue(`receipt.${index}.collectionDate`, newCollectionDate);
    }

    return (
        <tr className="TableContainer">
            <td
                data-label="Client"
                className={errors?.receipt?.[index]?.customerName && "ErrorTable"}
            >
                <FormTextInput
                    popUpMessage={
                        getValues(`term.${index}`)
                            ? "La ligne Recette associée sera aussi supprimée !"
                            : null
                    }
                    isDeletable={true}
                    Deletable={() => deleteReceiptLine(index)}
                    onSelect={() => HandleSelection("customerName")}
                    name={`receipt.${index}.customerName`}
                    required
                />
            </td>

            <td
                data-label="Type de prestation"
                className={errors?.receipt?.[index]?.serviceType && "ErrorTable"}
            >
                <FormTextInput
                    onSelect={() => HandleSelection("serviceType")}
                    name={`receipt.${index}.serviceType`}
                    required
                />
            </td>
            <td
                data-label="Codification produit"
                className={errors?.receipt?.[index]?.productCode && "ErrorTable"}
            >
                <SelectFromList
                    onSelect={() => HandleSelection("productCode")}
                    name={`receipt.${index}.productCode`}
                    required
                    choices={[
                        {value: "70.1", label: "70.1"},
                        {value: "74", label: "74"},
                    ]}
                />
            </td>
            <td
                data-label="Prévisionnel HT"
                className={errors?.receipt?.[index]?.forecastHT && "ErrorTable"}
            >
                <FormTextInput
                    onInputChange={(e) => onHTChange(e.target.value)}
                    name={`receipt.${index}.forecastHT`}
                    onSelect={() => HandleSelection("forecastHT")}
                />
            </td>
            <td
                data-label="Prévisionnel TTC"
                className={errors?.receipt?.[index]?.forecastTTC && "ErrorTable"}
            >
                <FormTextInput
                    onInputChange={(e) => onTTCChange(e.target.value)}
                    name={`receipt.${index}.forecastTTC`}
                    required
                    onSelect={() => HandleSelection("forecastTTC")}
                />
            </td>
            <td
                data-label="Date de Facturation"
                className={
                    errors?.receipt?.[index]?.invoiceDate ? "Date ErrorTable" : "Date"
                }
            >
                <CalendarInput
                    name={`receipt.${index}.invoiceDate`}
                    onSelect={() => HandleSelection("invoiceDate")}
                />
            </td>
            <td
                data-label="Date d'encaissement"
                className={
                    errors?.receipt?.[index]?.collectionDate ? "Date ErrorTable" : "Date"
                }
            >
                <CalendarInput name={`receipt.${index}.collectionDate`}/>
            </td>
            <td
                data-label="Année de prise en charge"
                className={errors?.receipt?.[index]?.supportYear && "ErrorTable"}
            >
                <FormTextInput
                    onSelect={() => HandleSelection("supportYear")}
                    name={`receipt.${index}.supportYear`}
                    required
                />
            </td>
            <td
                data-label="Commentaire"
                className={errors?.receipt?.[index]?.comment && "ErrorTable"}
            >
                <FormTextInput name={`receipt.${index}.comment`}/>
            </td>
        </tr>
    );
}

export default RecetteSection;
