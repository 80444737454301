import React from "react";
import "./SimplePopUpComponent.css";
import OutsideAlerter from "./OutsideAlerter";

function SimplePopUpComponent(props) {
  return (
    <OutsideAlerter ActionToDo={() => props.setPopupVisible(false)}>
      <div className="SimplePopUp">{props.children}</div>
    </OutsideAlerter>
  );
}

export default SimplePopUpComponent;
