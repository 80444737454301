import apirequest from "../apiconf";

export default async function CopyCard(cardID) {
  apirequest({
    link: "card/copy/" + cardID,
    method: "POST",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}
