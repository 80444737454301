import React, {useEffect, useState} from "react";
import "./Depense.Section.css";
import SelectFromList from "../../components/form/SelectFromList.form";
import DeleteFormPartByID from "../../data/GET/DELETE/DeleteFormPartByID";

import CalendarInput from "../../components/form/CalendarInput.form";

import {useFieldArray, useFormContext} from "react-hook-form";
import FormTextInput from "../../components/form/TextInput.form";

function DepenseSection() {
    const {
        fields: expenseFields,
        append: expenseAppend,
        remove: expenseRemove,
    } = useFieldArray({
        name: "expense",
    });
    useEffect(() => {
        if (expenseFields.length === 0) {
            expenseAppend(
                {
                    supplierName: "",
                    presentationType: "",
                    loadCoding: "",
                    estimatedAmount: "",
                    isHT: true,
                    isRecoverableTVA: "",
                    invoiceDate: "",
                    paymentDate: "",
                    comment: "",
                },
                {shouldFocus: false}
            );
        }
    }, []);

    function deleteExpenseLine(index) {
        let possibleExpenseID = getValues(`expense.${index}.id`);
        if (possibleExpenseID) {
            DeleteFormPartByID("expense", possibleExpenseID).then((response) => {
                if (response === 204) {
                    console.log("Deleted correctly");
                } else {
                    console.log(
                        "NOT DELETED: The ressource could not be deleted on the server, request status : ",
                        response
                    );
                }
            });
        }
        expenseRemove(index);
    }

    const {watch, getValues} = useFormContext();
    // Gestion du responsive sur un tableau aussi grand :
    // Plein écran : Tableau normal (> 795px)
    // Petit écran : Tableau inversé (Individuels par collonne)

    //Calcul Reliquat HT et TTC :
    const [RelicatHT, setRelicatHT] = useState();

    function ActualiseRelicats() {
        if (getValues("HT") && getValues("totalSstAmountPlanned")) {
            let SommeDepenseHT = 0;
            let SommeDepenseTTC = 0;
            for (const line in expenseFields) {
                SommeDepenseHT =
                    SommeDepenseHT +
                    Number(getValues("expense[" + line + "].estimatedAmount"));

            }
            if (getValues("HT") === "true") {
                setRelicatHT(
                    (
                        Number(getValues("totalSstAmountPlanned")) -
                        SommeDepenseHT -
                        SommeDepenseTTC * (1 / 1.2).toFixed(2)
                    ).toFixed(2)
                );
            } else if (getValues("HT") === "false") {
                setRelicatHT(
                    (
                        Number(getValues("totalSstAmountPlanned")) * (1 / 1.2) -
                        SommeDepenseHT -
                        SommeDepenseTTC * (1 / 1.2)
                    ).toFixed(2)
                );
            }
        } else {
            if (!getValues("HT")) {
                setRelicatHT("HT / TTC non indiqué");
            } else {
                setRelicatHT("Calcul impossible");
            }
        }
    }

    //Actualisation si montant total SST ou HT/TTC change
    useEffect(() => {
        ActualiseRelicats();
    }, [watch("HT"), watch("totalSstAmountPlanned")]);
    return (
        <>
            <div className="RelicatsContainer">
                <div className="Relicats">
                    <h4>Total SST HT prévu</h4>
                    <div>
                        {`${watch("totalSstAmountPlanned")} euros` || "Non indiqué"}
                    </div>
                </div>
                <div className="Relicats">
                    <h4>Reliquat (HT)</h4>
                    <div>{RelicatHT || 0}</div>
                </div>
            </div>
            <div className="SectionTableau">
                <table>
                    <thead>
                    <tr>
                        <th scope="col">Fournisseur</th>
                        <th scope="col">Type de service</th>
                        <th scope="col">Codification charge</th>
                        <th scope="col">Prévisionnel</th>
                        <th scope="col">TVA récupérable&nbsp;?</th>
                        <th scope="col">Date de facturation</th>
                        <th scope="col">Date de paiement</th>
                        <th scope="col">Commentaire</th>
                    </tr>
                    </thead>
                    <tbody // test
                    >
                    {expenseFields.map((field, index) => (
                        <TableLine
                            key={field.id}
                            index={index}
                            deleteExpenseLine={deleteExpenseLine}
                            ActualiseRelicats={() => ActualiseRelicats()}
                        ></TableLine>
                    ))}
                    </tbody>
                </table>
                <input
                    type="button"
                    className="formButton"
                    onClick={() =>
                        expenseAppend({
                            supplierName: "",
                            presentationType: "",
                            loadCoding: "",
                            estimatedAmount: "",
                            isHT: true,
                            isRecoverableTVA: "",
                            invoiceDate: "",
                            paymentDate: "",
                            comment: "",
                        })
                    }
                    value="Ajouter une dépense"
                />
                <br/>
                <br/>
            </div>
        </>
    );
}

function TableLine({index, deleteExpenseLine, key, ActualiseRelicats}) {
    const {
        watch,
        getValues,
        setValue,
        formState: {errors},
    } = useFormContext();

    useEffect(() => {
        if (getValues("expense[" + index + "].isHT")) ActualiseRelicats();
    }, [
        watch("expense[" + index + "].isHT"),
        watch(`expense.${index}.estimatedAmount`),
    ]);


    const dateValues = getValues([`expense.${index}.invoiceDate`, `expense.${index}.paymentDate`])
    const newCollectionDate = new Date(dateValues[0]);
    newCollectionDate.setMonth(newCollectionDate.getMonth() + 1);
    let collectionDate = new Date(dateValues[0])
    if (dateValues[1])
        collectionDate = new Date(dateValues[1]);
    if (newCollectionDate.toLocaleDateString() != collectionDate.toLocaleDateString()) {

        setValue(`expense.${index}.paymentDate`, newCollectionDate);
    }
    return (
        <tr className="TableContainer">
            <td
                data-label="Fournisseur"
                className={errors?.expense?.[index]?.supplierName && "ErrorTable"}
            >
                <FormTextInput
                    isDeletable={true}
                    key={key}
                    Deletable={() => deleteExpenseLine(index)}
                    name={`expense.${index}.supplierName`}
                />
            </td>
            <td
                data-label="Type de prestation"
                className={errors?.expense?.[index]?.presentationType && "ErrorTable"}
            >
                <FormTextInput key={key} name={`expense.${index}.presentationType`}/>
            </td>
            <td
                data-label="Codification charge"
                className={errors?.expense?.[index]?.loadCoding && "ErrorTable"}
            >
                <SelectFromList
                    key={key}
                    name={`expense.${index}.loadCoding`}
                    choices={[
                        {value: "60.1", label: "60.1"},
                        {value: "61.2", label: "61.2"},
                        {value: "62.1", label: "62.1"},
                        {value: "62.2", label: "62.2"},
                        {value: "62.3", label: "62.3"},
                    ]}
                />
            </td>
            <td
                data-label="Prévisionnel"
                className={errors?.expense?.[index]?.estimatedAmount && "ErrorTable"}
            >
                <FormTextInput key={key} name={`expense.${index}.estimatedAmount`}/>
            </td>
            <td
                data-label="TVA Récupérable ?"
                className={errors?.expense?.[index]?.isRecoverableTVA && "ErrorTable"}
            >
                <SelectFromList
                    key={key}
                    name={`expense.${index}.isRecoverableTVA`}
                    choices={[
                        {value: true, label: "Oui"},
                        {value: "false", label: "Non"},
                    ]}
                />
            </td>
            <td
                data-label="Date de facturation"
                className={
                    errors?.expense?.[index].invoiceDate ? "Date ErrorTable" : "Date"
                }
            >
                <CalendarInput key={key} name={`expense.${index}.invoiceDate`}/>
            </td>
            <td
                data-label="Date de paiement"
                className={
                    errors?.expense?.[index]?.paymentDate ? "Date ErrorTable" : "Date"
                }
            >
                <CalendarInput key={key} name={`expense.${index}.paymentDate`}/>
            </td>
            <td
                data-label="Commentaire"
                className={errors?.expense?.[index]?.comment && "ErrorTable"}
            >
                <FormTextInput key={key} name={`expense.${index}.comment`}/>
            </td>
        </tr>
    );
}

export default DepenseSection;
