import apirequest from "../apiconf";

export default async function SearchCard(string) {
  const response = await apirequest({
    link: "card/search",
    method: "POST",
    rawData: { search_value: string },
  });
  return response;
}
