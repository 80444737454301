import apirequest from "../data/apiconf";

class Auth {
  constructor() {
    this.authenticated = false;
    this.token = null;
    this.state = null;
  }

  login(props) {
    apirequest({
      link: "login_check",
      method: "POST",
      rawData: props.loginddata,
    }).then((response) => {
      if (response?.token) {
        this.token = response.token;
        this.state = null;
        this.authenticated = true;
        props.cb();
      } else {
        this.state = "401";
        props.failure();
      }
    });
  }

  changePassword(props) {
    apirequest({
      link: "admin/change_password",
      method: "POST",
      rawData: props.changePasswordData,
    }).then((response) => {
      if (response?.token) {
        props.cb();
      } else {
        this.state = "401";
        props.failure();
      }
    });
  }

  logout(cb) {
    this.authenticated = false;
    this.token = null;
    this.state = null;
    cb();
  }

  isAuthentificated() {
    return this.authenticated;
  }
}

export default new Auth();
