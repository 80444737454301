import apirequest from "./apiconf";

//Empty Form

export default async function GetAllBudget() {
  const response = await apirequest({
    link: "budget",
    method: "GET",
  });
  return response;
}
