import React, { useState } from "react";
import "./PasswordPage.css";
import lockIcon from "./icons/lockIcon.svg";
import auth from "./components/auth";
import { useForm } from "react-hook-form";

function onSubmitForm(formData) {
  return;
}
function Input(props) {
  return (
    <div className="Input">
      <img src={props.icon} className="Icon" alt="" />
      <input
        onChange={(e) => props.onChange(e.target.value)}
        value={props.value}
        className="PlaceHolder"
        placeholder={props.placeholder}
        type={props.type}
        name={props.name}
      />
    </div>
  );
}

function Button(props) {
  const [errorMessage, setErrormessage] = useState(auth.state);
  return (
    <>
      {errorMessage}
      <button
        onClick={() => {
          auth.changePassword({
            changePasswordData: {
              password: props.valuePassword,
            },
            cb: () => {
              props.history.push("/");
            },
            failure: () => {
              setErrormessage("Informations invalides");
            },
          });
        }}
        className="Button"
        disabled={props.isDiabled}
      >
        <img src={lockIcon} className="IconQuit" disabled alt="EnterIcon" />
        <div className="Buttontext">{props.children}</div>
      </button>
    </>
  );
}
function PasswordPage(props) {
  const { register, handleSubmit } = useForm();

  const [isButtonDisabled, setisButtonDisabled] = useState(true);
  const [valueConfirmPassword, setvalueConfirmPassword] = useState("");
  const [valuePassword, setvaluePassword] = useState("");

  function handleConfirmPassword(value) {
    setvalueConfirmPassword(value);
    setisButtonDisabled(!((value !== "" && valuePassword !== "") && (value === valuePassword)));
  }

  function handlePassword(value) {
    setvaluePassword(value);
    setisButtonDisabled(!((value !== "" && valuePassword !== "") && (value === valueConfirmPassword)));
  }

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <div className="PasswordForm">
        <Input
          {...register("Password", {
            required: true,
          })}
          onChange={handlePassword}
          value={valuePassword}
          icon={lockIcon}
          placeholder="Nouveau mot de passe"
          type="password"
          name="password"
        />
        <Input
          {...register("confirmPassword", {
            required: true,
          })}
          onChange={handleConfirmPassword}
          value={valueConfirmPassword}
          icon={lockIcon}
          placeholder="Confirmer"
          type="password"
          name="confirmPassword"
        />
        <Button
          history={props.history}
          valuePassword={valuePassword}
          isDiabled={isButtonDisabled}
        >
          Changer le mot de passe
        </Button>
      </div>
    </form>
  );
}

export default PasswordPage;
