import React, {useEffect, useState} from "react";
import "./TermeDePaiement.Section.css";
import CalendarInput from "../../components/form/CalendarInput.form";
import {useFormContext, useWatch} from "react-hook-form";
import FormTextInput from "../../components/form/TextInput.form";
function TermeDePaiement({
  addNewLineTermeDePaiement,
  deleteTermLine,
  termFields,
}) {
  const {getValues, watch} = useFormContext();
  const [fieldNames, setFieldNames] = useState([]);
  const [displayError, setDisplayError] = useState(false);

  function paymentsAreCompleted() {
    const totalAmount = getValues("amount");
    const amountValues = getValues(fieldNames.map((fields) => {
      return fields[0]
    }))

    const totalAmountValues = amountValues.reduce((previousValue, currentValue) => {
      return previousValue + +currentValue;
    }, 0)
    if (totalAmountValues === +totalAmount)
      return true;

    const ratesValues = getValues(fieldNames.map((fields) => {
      return fields[1]
    }))

    const totalRatesValues = ratesValues.reduce((previousValue, currentValue) => {
      return previousValue + +currentValue;
    }, 0)
    return totalRatesValues === 100;


  }

  function computeAllNames() {
    const res = termFields.map((field, index) => ([`term.${index}.amount`, `term.${index}.rate`]));
    setFieldNames(res);
  }

  useEffect(() => {
    computeAllNames()
  }, [termFields.length])

  useWatch()
  useEffect(() => {
     const shouldDisplayError = !paymentsAreCompleted();
     if (shouldDisplayError !== displayError)
       setDisplayError(shouldDisplayError);
  }, [watch(fieldNames.flat())])

  return (
    <>
      <h5>Termes de paiement</h5>
      <div className="TermePaiementContainer">
        {
          displayError && (
            <div className="ErrorTerme">Le montant des fiches ne correspond pas au montant total.</div>
          )
        }
        {termFields.map((field, index) => (
          <Line
            index={index}
            key={field.id}
            deleteTermLine={() => deleteTermLine(index)}
          ></Line>
        ))}

        <input
          type="button"
          className="formButton"
          onClick={() => addNewLineTermeDePaiement()}
          value="Ajouter un paiement"
        />
      </div>
    </>
  );
}

function Line({ index, key, deleteTermLine }) {

  const {
    formState: { errors },
    getValues, watch, setValue
  } = useFormContext();
  let label = "Versement " + (index + 1);
  useEffect(() => {
    const delayAutoCompleteAmount = setTimeout(() => {
      if (getValues(`term.${index}.amount`) && getValues(`term.${index}.rate`))
        return

      if (getValues(`term.${index}.amount`)) {
        const newValue = Math.round(getValues(`term.${index}.amount`) / getValues(`amount`) * 100 * 100) / 100;
        setValue(`term.${index}.rate`, newValue)
        return;
      }

      if (getValues(`term.${index}.rate`)) {
        const newValue = Math.round(getValues(`term.${index}.rate`) * getValues(`amount`)) / 100;
        setValue(`term.${index}.amount`, newValue)
      }
    }, 2000)

    return () => clearTimeout(delayAutoCompleteAmount)
      }, [watch([`term.${index}.amount`, `term.${index}.rate`, `amount`])])

  return (
    <div className={"FormLine FourItemsPerLine"}>
      <FormTextInput
        popUpMessage={
          getValues(`receipt.${index}`)
            ? "La ligne Recette associée sera aussi supprimée !"
            : null
        }
        isDeletable={
          getValues(`receipt.${index}.id`) === undefined &&
          getValues(`term.${index}.id`) === undefined
        }
        Deletable={() => deleteTermLine(key)}
        name={`term.${index}.paymentName`}
        label={label}
        key={key}
        required
        nestedErrorValue={errors?.term?.[index]?.paymentName?.message}
      />
      <CalendarInput
        key={key}
        required
        name={`term.${index}.paymentDate`}
        label="Date"
        nestedErrorValue={errors?.term?.[index]?.paymentDate?.message}
      />
      <FormTextInput
        key={key}
        name={`term.${index}.amount`}
        label="Montant"
        nestedErrorValue={errors?.term?.[index]?.amount?.message}
      />
      <FormTextInput
        key={key}
        name={`term.${index}.rate`}
        label=""
        percent
        nestedErrorValue={errors?.term?.[index]?.rate?.message}
      />
    </div>
  );
}
export default TermeDePaiement;
