import React, { useState } from "react";
import { useFormContext } from "react-hook-form";

import "./TextInput.form.css";
import "./SelectFromList.form.css";
import chevrondown from "../../icons/down-chevron.svg";
function SelectFromList({
  name,
  required,
  pattern,
  onSelect,
  choices,
  ...props
}) {
  const {
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  //On utilise un placeholder Custom donc on gère son affichage grace à une classe "invisible"
  const [isPlaceholderVisible, setPlaceholderVisible] = useState(
    "InputFormListPlaceHolder"
  );
  //On extrait toutes les valeurs des choix :
  let possibleValues = choices.map((choice) => {
    return choice.value.toString();
  });
  //On gère l'affichage si le choix est un choix exitant (ou valeur booleenne pour form chargés depuis l'api)

  //Si la donnée est inexistante dans la liste on la supprime
  if (!possibleValues.includes(watch(name)?.toString())) {
    if (watch(name)) {
      setValue(name, null, {
        shouldValidate: false,
        shouldTouch: false,
      });
    }
    //On remet le placeholder si il n'y est pas
    if (isPlaceholderVisible !== "InputFormListPlaceHolder") {
      setPlaceholderVisible("InputFormListPlaceHolder");
    }
  } else {
    //Sinon on enlève le placeholder si il y est
    if (isPlaceholderVisible !== "InputFormListPlaceHolder invisible") {
      setPlaceholderVisible("InputFormListPlaceHolder invisible");
    }
    //On transforme les booleans en string pour uniformiser le formulaire et la vérification
    if (!possibleValues.includes(getValues(name))) {
      setValue(name, watch(name).toString(), {
        shouldValidate: true,
        shouldTouch: false,
      });
    }
  }

  let requiredMessage;
  if (required) {
    requiredMessage = "Champ obligatoire";
  } else {
    requiredMessage = false;
  }

  //gestion Field Pattern
  let fieldPattern;
  if (pattern) {
    fieldPattern = pattern;
  }

  let [valueSelect, SetValueSelect] = useState(watch(name));
  function ChangeValue(value) {
    if (onSelect) {
      onSelect();
    }
    SetValueSelect(value);
    setValue(name, value, {
      shouldValidate: true,
      shouldTouch: false,
    });
  }

  const label = required ? props.label ?  props.label + " *" : undefined : props.label
  return (
    <div className="InputFormContainer">
      <div className={isPlaceholderVisible}>{label}</div>
      <img src={chevrondown} className="ChevronList" alt="v" />
      <label style={{visibility: "hidden"}}>{label}</label>
      <div className="InputForm">
        <select
          key={props?.key}
          className="InputFormList"
          name={name}
          {...register(name, {
            required: requiredMessage,
            pattern: fieldPattern,
          })}
          value={valueSelect}
          onChange={(e) => ChangeValue(e.target.value)}
        >
          <option></option>
          {choices.map((element) => (
            <OptionElement key={element.value} element={element} />
          ))}
        </select>
      </div>
      <div className="ErrorForm">{errors[name] && errors[name].message}</div>
    </div>
  );
}
function OptionElement({ element }) {
  return <option value={element.value}>{element.label}</option>;
}

export default SelectFromList;
