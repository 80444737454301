import React, { useState } from "react";
import FormTextInput from "../../components/form/TextInput.form";
import SelectFromList from "../../components/form/SelectFromList.form";
import { useFormContext } from "react-hook-form";

import CalendarInput from "../../components/form/CalendarInput.form";
import TermeDePaiment from "./TermeDePaiement.Section";
import Deliverable from "./Deliverable.Section";
import "./General.Section.css";
function GeneralSection({
  //Terme de paiement
  addNewLineTermeDePaiement,
  deleteTermLine,
  termFields,
  CheckIfLoadedByForm,
}) {
  const { watch, getValues, setValue } = useFormContext();
  //Gestion de l'autocompletition

  //Gestion du contact pour l'autocompletition
  const [InputHasBeenSelected, setInputHasbeenSelected] = useState({
    productCoding: CheckIfLoadedByForm("productCoding"),
  });

  function HandleSelection(field) {
    let SelectedInputs = InputHasBeenSelected; //Is Read Only
    SelectedInputs[field] = true;
    setInputHasbeenSelected(SelectedInputs);
  }

  if (!InputHasBeenSelected.productCoding) {
    if (
      watch("subsidy") === "commande" &&
      getValues("productCoding") !== "70.1"
    ) {
      setValue("productCoding", "70.1", {
        shouldValidate: true,
        shouldTouch: false,
      });
    } else if (
      watch("subsidy") === "subvention" &&
      getValues("productCoding") !== "74"
    ) {
      setValue("productCoding", "74", {
        shouldValidate: true,
        shouldTouch: false,
      });
    }
  }

  return (
    <div className="GeneralForm">
      <div className="FormLine TwoItems">
        <FormTextInput
          name="year"
          label="Année de création"
          required
          pattern={{
            value: /^[0-9][0-9][0-9][0-9]$/i,
            message: "Merci d'indiquer une année",
          }}
        />
        <FormTextInput
          name="agreementNumber"
          label="N° de convention/commande"
          required
        />
      </div>
      <div className="FormLine">
        <FormTextInput name="allocationCode" label="Code imputation" required />
        <FormTextInput name="customer" label="Client" required />
        <FormTextInput name="project" label="Projet" required />
      </div>
      <div className="FormLine">
        <SelectFromList
          name="subsidy"
          label="Commande / Subvention"
          required
          choices={[
            { value: "commande", label: "Commande" },
            { value: "subvention", label: "Subvention" },
          ]}
        />
        <FormTextInput
          name="subsidyRate"
          label="Taux de Subvention"
          required
          percent
        />
        <FormTextInput name="comment" label="Commentaire(s)" />
      </div>
      <div className="FormLine">
        <SelectFromList
          onSelect={() => HandleSelection("productCoding")}
          name={"productCoding"}
          required
          label="Codification produit"
          choices={[
            { value: "70.1", label: "70.1" },
            { value: "74", label: "74" },
          ]}
        />
        <FormTextInput name="amount" label="Montant" required />
        <SelectFromList
          name="HT"
          label="HT / TTC"
          required
          choices={[
            { value: true, label: "HT" },
            { value: false, label: "Tout compris" },
          ]}
        />
      </div>
      <div className="FormLine OneMiddle">
        <div className="TextInForm">TVA Récupérable ?</div>
        <SelectFromList
          name="recoverableTVA"
          label="Oui / Non"
          required
          choices={[
            { value: true, label: "Oui" },
            { value: false, label: "Non" },
          ]}
        />
      </div>
      <div className="FormLine">
        <CalendarInput name="orderDate" label="Date de commande" />
        <CalendarInput name="startingDate" label="Date de démarrage" />
        <CalendarInput required name="endingDate" label="Date de clôture" />
      </div>
      <div className="FormLine">
        <SelectFromList
          name="serviceType"
          required
          choices={[
            { value: "Annuaire", label: "Annuaire" },
            { value: "Atelier", label: "Atelier" },
            { value: "Etude", label: "Étude" },
            { value: "Event Autre", label: "Évènement autre" },
            { value: "Fiche Pratique", label: "Fiche pratique" },
            { value: "Forum", label: "Forum" },
            { value: "Observatoire ", label: "Observatoire " },
            { value: "Rencontre Ecotech", label: "Rencontre Ecotech" },
            { value: "Rencontre Finance", label: "Rencontre Finance" },
            { value: "Trophees", label: "Trophées" },
            { value: "Vitrine Innovation", label: "Vitrine innovation" },
          ]}
          label="Type de prestation"
        />
        <FormTextInput
          name="totalSstAmountPlanned"
          label="Total SST HT prévu"
          required
        />
        <FormTextInput
          name="totalPersonnelAmountPlanned"
          label="Dépense de personnel prévu"
          longlabel
          required
        />
      </div>
      <div className="FormLine OneEnd">
        <FormTextInput name="pilot" label="Pilote" />
      </div>
      <div className="SousSectionGeneral">
        <TermeDePaiment
          addNewLineTermeDePaiement={addNewLineTermeDePaiement}
          deleteTermLine={deleteTermLine}
          termFields={termFields}
        />
      </div>
      <div className="SousSectionGeneral">
        <Deliverable />
      </div>
      <br />
      <br />
    </div>
  );
}

export default GeneralSection;
