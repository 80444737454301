import React from "react";
import "./PopUpComponent.css";
import OutsideAlerter from "./OutsideAlerter";

function PopUpComponent(props) {
  return (
    <div className="PopUpContainer">
      <OutsideAlerter ActionToDo={() => props.setPopupVisible(false)}>
        <div className="PopUp">{props.children}</div>
      </OutsideAlerter>
    </div>
  );
}

export default PopUpComponent;
