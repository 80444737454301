import React, {useEffect, useState} from "react";
import "./FichesPage.css";
import chevrondown from "./icons/down-chevron.svg";
import OutsideAlerter from "./components/OutsideAlerter";
import PopUpComponent from "./components/PopUpComponent";
import SearchIcon from "./icons/search.png";
import GetAllFiches from "./data/GetAllFiches";
import SearchCard from "./data/GET/SearchCard";
import DeleteCardByID from "./data/GET/DELETE/DeleteCardByID";
import GetFullCardByID from "./data/GetFullCardByID";
import ExportFile from "./data/GET/ExportFile";
import CopyCard from "./data/GET/CopyCard";
function FichesPage(props) {
  const [RequestedFiches, setRequestedFiches] = useState();
  const [RequestedYears, setRequestedYears] = useState();
  const [SelectedYear, setSelectedYear] = useState("Tout");
  const [FilteredFiches, setFilteredFiches] = useState();

  if (!Array.isArray(RequestedFiches)) {
    GetAllFiches(props).then((response) => {
      setRequestedFiches(response);
    });
  }
  function OpenFiche(Fiche) {
    GetFullCardByID(Fiche.id).then((response) => {
      props.history.push({
        pathname: "/",
        search: "",
        state: { Fiche: response },
      });
    });
  }

  const [isPopUpVisible, setPopupVisible] = useState(false);
  const [FicheIdConcerned, setFicheIdConcerned] = useState();

  function Search(value) {
    SearchCard(value).then((response) => {
      if (value) {
        if (response?.length > 0) {
          setRequestedFiches(response);
        } else {
          setRequestedFiches([]);
        }
      } else {
        GetAllFiches(props).then((response) => {
          setRequestedFiches(response);
        });
      }
    });
  }
  function DeleteFiche(FicheId) {
    setRequestedFiches();
    DeleteCardByID(FicheId).then(
      GetAllFiches(props).then((response) => {
        setRequestedFiches(response);
      })
    );

    setPopupVisible(false);
  }
  function DuplicateFiche(FicheId) {
    setRequestedFiches();
    CopyCard(FicheId).then(
      GetAllFiches(props).then((response) => {
        setRequestedFiches(response);
      })
    );

    setPopupVisible(false);
  }

  function getAllYears() {
    let years = [];

    if (RequestedFiches)
      years = RequestedFiches.map((Fiche) => {
        return Fiche.year;
      })
    setRequestedYears([...new Set(years)])
  }

  useEffect(() => {
    getAllYears();
  }, [RequestedFiches])

  function handleSelectYear(event) {
    setSelectedYear(event.target.value);
  }


  function filterByYear() {
    let fiches = [];
    if (RequestedFiches)
      fiches = RequestedFiches.filter((Fiche) => {
        return SelectedYear === "Tout" || SelectedYear === Fiche.year;
      })
    setFilteredFiches([...new Set(fiches)]);
  }

  useEffect(() => {
    filterByYear();
  }, [SelectedYear, RequestedYears])


  return (
    <div>
      {isPopUpVisible ? (
        <PopUpComponent setPopupVisible={setPopupVisible}>
          Êtes-vous sûr de vouloir supprimer cette fiche ?
          <div className="ButtonPopUp">
            <div
              className="LigneFicheOpenButton ButtonCancelPopUp"
              onClick={() => setPopupVisible(false)}
            >
              Annuler
            </div>
            <div
              className="LigneFicheOpenButton"
              onClick={() => DeleteFiche(FicheIdConcerned)}
            >
              Supprimer
            </div>
          </div>
        </PopUpComponent>
      ) : (
        <></>
      )}

      <div className="HomeContainer">
        <div className="SearchbarContainer">
          <div>
            <select name="selectYear" id="selectYear" onChange={handleSelectYear} className={"FilterFiches"}>
              <option value="Tout" >Tout</option>

              {
                RequestedYears && RequestedYears.map((year) => {
                  return <option value={year}>{year}</option>
                })
              }
            </select>
          </div>
          <div className="SearchbarSubContainer">
            <img className="SearchIcon" src={SearchIcon} alt="" />
            <input
              className="SearchBar"
              placeholder="Rechercher"
              onChange={(e) => Search(e.target.value)}
            />
          </div>{" "}
        </div>
        <div className="PageContent PageFiche">
          {Array.isArray(FilteredFiches) && FilteredFiches?.length > 0
            ? FilteredFiches.map((Fiche) => (
                <LigneFiche
                  key={Fiche.id}
                  Fiche={Fiche}
                  setPopupVisible={setPopupVisible}
                  OpenFiche={() => OpenFiche(Fiche)}
                  setIdConcerned={() => setFicheIdConcerned(Fiche.id)}
                  DuplicateThisFiche={() => DuplicateFiche(Fiche.id)}
                  props={props}
                />
              ))
            : FilteredFiches?.length === 0
            ? "Aucune fiche trouvé"
            : "Recherche en cours...."}
        </div>
      </div>
    </div>
  );
}

function LigneFiche({
  OpenFiche,
  Fiche,
  setPopupVisible,
  setIdConcerned,
  DuplicateThisFiche,
}) {
  const [isActionVisible, setActionVisible] = useState(false);
  const date = new Date();

  return (
    <div className="LigneFicheContainer">
      <div className="SectionLigneFiche">
        <div className="LigneFicheData">{Fiche.year}</div>
      </div>

      <div className="SectionLigneFiche">
        <div className="LigneFicheData">{Fiche.allocationCode}</div>
      </div>
      <div className="SectionLigneFiche">
        <div className="LigneFicheData">{Fiche.customer}</div>
      </div>
      <div className="SectionLigneFiche">
        <div className="LigneFicheData">{Fiche.project}</div>
      </div>
      <div className="SectionLigneFiche BoutonsFiches">
        <div className="LigneFicheOpenButton" onClick={() => OpenFiche()}>
          Ouvrir
        </div>
        <OutsideAlerter ActionToDo={() => setActionVisible(false)}>
          <div
            className="LigneFicheActionButton"
            onClick={() => {
              setActionVisible(!isActionVisible);
            }}
          >
            Action
            <img src={chevrondown} className="ChevronAction" alt="v" />
          </div>
          {isActionVisible && (
            <div className="ActionMenu Fiche">
              <div
                onClick={() =>
                  ExportFile({
                    path: "card/export/",
                    name: "Fiche",
                    ID: Fiche.id,
                    fileName:
                      Fiche.year + "_" + Fiche.customer + "_" + Fiche.project + "_" + date.toLocaleDateString("fr") + "_" + date.toLocaleTimeString("fr"),
                  })
                }
                className="buttonAction uno"
              >
                Exporter
              </div>
              <div
                onClick={() => DuplicateThisFiche()}
                className="buttonAction uno"
              >
                Dupliquer
              </div>
              <div
                className="buttonAction dos"
                onClick={() => {
                  setPopupVisible(true);
                  setIdConcerned();
                }}
              >
                Supprimer
              </div>
            </div>
          )}
        </OutsideAlerter>
      </div>
    </div>
  );
}
export default FichesPage;
