import apirequest from "../apiconf";

function formatDate(date) {
  if (date) {
    date = new Date(date);
    let day = date.getDate();

    let monthIndex = date.getMonth() + 1;

    let year = date.getFullYear();
    return String(`${day}-${monthIndex}-${year}`);
  }
  return null;
}

function formatBool(string) {
  if (string === "true") {
    return true;
  }
  if (string === "false") {
    return false;
  }
  return;
}
function formatID(id) {
  if (id) {
    return Number(id);
  }
  return 0;
}
function formatCard(card) {
  card = JSON.stringify({
    id: formatID(card?.id),
    year: Number(card.year),
    agreementNumber: String(card.agreementNumber),
    allocationCode: String(card.allocationCode),
    customer: String(card.customer),
    project: String(card.project),
    subsidy: String(card.subsidy),
    subsidyRate: Number(card.subsidyRate),
    comment: String(card.comment),
    productCoding: String(card.productCoding),
    amount: Number(card.amount),
    HT: formatBool(card.HT),
    recoverableTVA: formatBool(card.recoverableTVA),
    orderDate: formatDate(card.orderDate),
    startingDate: formatDate(card.startingDate),
    endingDate: formatDate(card.endingDate),
    serviceType: String(card.serviceType),
    totalSstAmountPlanned: Number(card.totalSstAmountPlanned),
    totalPersonnelAmountPlanned: Number(card.totalPersonnelAmountPlanned),
    pilot: String(card.pilot),
  });
  return card;
}
function formatReceipt(receipt) {
  let formatreceipt = {
    customerName: String(receipt.customerName),
    serviceType: String(receipt.serviceType),
    productCode: String(receipt.productCode),
    forecastHT: Number(receipt.forecastHT),
    forecastTTC: Number(receipt.forecastTTC),
    supportYear: Number(receipt.supportYear),
    comment: String(receipt.comment),
  };
  if (receipt.invoiceDate || receipt?.id) {
    formatreceipt.invoiceDate = formatDate(receipt.invoiceDate);
  }
  if (receipt.collectionDate || receipt?.id) {
    formatreceipt.collectionDate = formatDate(receipt.collectionDate);
  }
  formatreceipt = JSON.stringify(formatreceipt);
  return formatreceipt;
}
function formatTerm(term) {
  term = JSON.stringify({
    paymentName: String(term?.paymentName),
    paymentDate: formatDate(term?.paymentDate),
    amount: Number(term?.amount),
    rate: Number(term?.rate),
  });
  return term;
}

function formatDeliverable(deliverable) {
  deliverable = JSON.stringify({
    name: String(deliverable.name),
    applicationDate: formatDate(deliverable.applicationDate),
    comment: String(deliverable.comment),
  });
  return deliverable;
}

function formatExpense(expense) {
  let formatexpense = {
    supplierName: String(expense.supplierName),
    presentationType: String(expense.presentationType),
    loadCoding: String(expense.loadCoding),
    estimatedAmount: Number(expense.estimatedAmount),
    isHT: formatBool(expense.isHT),
    isRecoverableTVA: formatBool(expense.isRecoverableTVA),
    comment: String(expense.comment),
  };
  if (expense.invoiceDate || expense?.id) {
    formatexpense.invoiceDate = formatDate(expense.invoiceDate);
  }
  if (expense.collectionDate || expense?.id) {
    formatexpense.paymentDate = formatDate(expense.paymentDate);
  }
  formatexpense = JSON.stringify(formatexpense);
  return formatexpense;
}

async function PostCard(props) {
  if (props?.cardId) {
    const response = await apirequest({
      link: "card/" + props.cardId,
      method: "PUT",
      rawData: props.card,
    });
    return response;
  } else {
    const response = await apirequest({
      link: "card",
      method: "POST",
      rawData: props.card,
    });
    return response;
  }
}
async function PostElement(props) {
  //Si l'element a déjà un ID on update l'element concerné
  if (props?.ElementId) {
    //console.log("LOGGER PUT", props.link, props.ElementId);
    const response = await apirequest({
      link: props.link + "/" + props.ElementId,
      method: "PUT",
      rawData: props.element,
    });
    return response;
  } else {
    const response = await apirequest({
      link: props.link + "/" + props.CardId,
      method: "POST",
      rawData: props.element,
    });
    return response;
  }
}

export default async function PostForm(formdata) {
  //card in JSON
  let card = formatCard(formdata);
  let Loadedid = formdata.id;
  const PostedCard = await PostCard({ card, cardId: Loadedid }).then(
    (PostedCard) => {
      if (PostedCard) {
        //console.log(PostedCard);
        formdata.receipt.forEach((receipt) => {
          PostElement({
            element: formatReceipt(receipt),
            CardId: PostedCard.id,
            ElementId: receipt?.id,
            link: "receipt",
          });
        });
        formdata.deliverable.forEach((deliverable) => {
          PostElement({
            element: formatDeliverable(deliverable),
            CardId: PostedCard.id,
            ElementId: deliverable?.id,
            link: "deliverable",
          });
        });
        formdata.term.forEach((term) => {
          PostElement({
            element: formatTerm(term),
            CardId: PostedCard.id,
            ElementId: term?.id,
            link: "term",
          });
        });
        formdata.expense.forEach((expense) => {
          PostElement({
            element: formatExpense(expense),
            CardId: PostedCard.id,
            ElementId: expense?.id,
            link: "expense",
          });
        });
      } else {
        throw new Error("Unable to post Card");
      }
    }
  );
}
