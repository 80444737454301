import auth from "../components/auth";

async function apirequest({ link, method, rawData, ...props }) {
  const APILINK = "https://api.pexe-budget.app/api/";

  //New request
  var CustomHeader = new Headers();
  if (auth.token) {
    CustomHeader.append("Authorization", "Bearer " + auth.token);
  }
  if (props?.download) {
  } else {
    CustomHeader.append("Content-Type", "application/json");
  }
  var data;

  // If the RawData is not string we stingify if -> We must not apply stringify twice
  if (typeof rawData === "string") {
    data = rawData;
  } else {
    data = JSON.stringify(rawData);
  }

  var requestOptions = {
    method: method,
    headers: CustomHeader,
    body: data,
  };
  const response = await fetch(APILINK + link, requestOptions);
  if (method !== "DELETE") {
    try {
      if (props?.download) {
        let result_1 = await response.blob();
        return result_1;
      } else {
        let result_2 = await response.json();
        if (result_2?.code === 401) {
          auth.logout(() =>
            props.history.push({
              pathname: "/login",
              search: "",
              state: {},
            })
          );
        }
        return result_2;
      }
    } catch (error) {
      console.log("error", error);
    }
  }
  return response;
}

export default apirequest;
