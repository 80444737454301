import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import "./CalendarInput.form.css";
import calendar from "../../icons/calendar.svg";
import { useFormContext } from "react-hook-form";

function CalendarInput({
  nestedErrorValue,
  label,
  required,
  name,
  onSelect,
  ...props
}) {
  const {
    register,
    setValue,
    formState: { errors },
    getValues,
    watch,
  } = useFormContext();
  registerLocale("fr", fr);
  const [startingDate, setstartingDate] = useState(
    Date.parse(getValues(name)) ? Date.parse(getValues(name)) : null
  );
  useEffect(() => {
    if (Date.parse(getValues(name))) {
      setstartingDate(Date.parse(getValues(name)));
    }
  }, [watch(name)]);

  function handleChange(date) {
    setValue(name, date, {
      shouldValidate: true,
      shouldTouch: false,
    });
    setstartingDate(date);
  }
  let defaultClass = "InputForm DateForm";
  if (props.longlabel) {
    defaultClass += " LongLabel";
  }
  let requiredMessage;
  if (required) {
    requiredMessage = "Champ obligatoire";
  } else {
    requiredMessage = false;
  }

  const inputLabel = required ? label ?  label + " *" : undefined : label

  return (
    <>
      <div className={"InputFormContainer DateFormContainer"}>
        <img src={calendar} className="CalendarIcon" alt="OpenIcon" />
        <label style={{visibility: "hidden"}}>{inputLabel}</label>
        <input
          key={props?.key}
          onSelect={onSelect}
          className="Hidden"
          defaultValue={startingDate}
          {...register(name, {
            required: requiredMessage,
          })}
        />
        <DatePicker
          popperPlacement="top"
          onSelect={onSelect}
          // showYearDropdown
          // scrollableYearDropdown
          //Peut être utile : closeOnScroll={true}
          onChange={(date) => handleChange(date)}
          selected={startingDate}
          // withPortal
          dateFormat="dd/MM/yyyy"
          locale="fr"
          className={defaultClass}
          placeholderText={inputLabel}
        />
        <div className="ErrorForm">
          {nestedErrorValue
            ? nestedErrorValue
            : errors[name] && errors[name].message}
        </div>
      </div>
    </>
  );
}

export default CalendarInput;
