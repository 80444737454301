import { Switch, Route } from "react-router-dom";
import Home from "./Accueil/HomePage";
import Login from "./Login/LoginPage";
import FichesPage from "./FichesPage";
import BudgetsPage from "./BudgetsPage";
import ProtectedRoute from "./components/protected.route";
import Header from "./components/header";
import "./App.css";
import PasswordPage from "./PasswordPage";
function App() {
  return (
    <div className="App">
      <ProtectedRoute path="*" component={Header} />

      <Switch>
        <ProtectedRoute exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <ProtectedRoute exact path="/Fiches" component={FichesPage} />
        <ProtectedRoute exact path="/Budgets" component={BudgetsPage} />
        <ProtectedRoute exact path="/Password" component={PasswordPage} />

        <ProtectedRoute path="*" component={() => "404 PAGE NOT FOUND"} />
      </Switch>
    </div>
  );
}

export default App;
