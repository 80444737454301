import React from "react";
import { useState } from "react";
import "./BudgetsPage.css";
import ExportFile from "./data/GET/ExportFile";
import GetAllBudget from "./data/GetAllBudget";

function BudgetsPage() {
  const [RequestedBudgects, setRequestedBudgects] = useState();

  if (!Array.isArray(RequestedBudgects)) {
    GetAllBudget().then((response) => {
      setRequestedBudgects(response.reverse());
    });
  }

  return (
    <div className="HomeContainer">
      <div className="PageContent PageContentBudget">
        {Array.isArray(RequestedBudgects) && RequestedBudgects?.length > 0
          ? RequestedBudgects.map((Budget) => (
              <LigneBudget key={Budget} Budget={Budget} />
            ))
          : RequestedBudgects?.length === 0
          ? "Aucun Budget trouvé"
          : "Recherche en cours...."}
      </div>
    </div>
  );
}

function LigneBudget({ Budget }) {
  const date = new Date();
  return (
    <div className="LigneFicheContainer">
      <div className="LigneFicheData">{Budget}</div>
      <div className="BoutonActionBudgets">
        <div
          onClick={() => {
            ExportFile({
              path: "budget/export/budget/",
              name: "Budget",
              ID: Budget,
              fileName: `Export Budget ${Budget}  du ${date.toLocaleDateString("fr")} à ${date.toLocaleTimeString("fr")}`
            });
          }}
          className="LigneFicheOpenButton LigneFicheActionButton"
        >
          Exporter
        </div>
      </div>
    </div>
  );
}
export default BudgetsPage;
