import menuIcon from "../icons/menuHamburger.svg";
import { HashLink } from "react-router-hash-link";
import React, { useState } from "react";

export default function MenuHamburger(props) {
  const [isVisible, setVisibility] = useState(false);

  if (isVisible) {
    return (
      <>
        <div className="Menu">
          <Item position="first" text="Général" />
          <Item position="middle" text="Recettes" />
          <Item position="last" text="Dépenses" />
        </div>
        <button className="button" onClick={() => setVisibility(!isVisible)}>
          <img alt="menu" src={menuIcon} className="IconMenu" />
        </button>
      </>
    );
  } else
    return (
      <button className="button" onClick={() => setVisibility(!isVisible)}>
        <img alt="menu" src={menuIcon} className="IconMenu" />
      </button>
    );
}
function Item(props) {
  let classlist = "MenuItem";
  classlist += " " + props.position + "Item";

  return (
    <HashLink smooth to={"/#" + props.text}>
      <div onClick={() => props.text} className={classlist}>
        {props.text}
      </div>
    </HashLink>
  );
}
